import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import INFO from "../../data/user";
import "./styles/project.css";
import Modal from "../common/modal";
import Tabs from "../common/tabs";

const Project = (props) => {
	const { tools, title, description, details } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<React.Fragment>
			<div className="project">
				<Link to="#" onClick={(e) => { e.preventDefault(); openModal(); }}>
					<div className="project-container">
						<div className="project-title">{title}</div>
						<div className="project-description">{description}</div>
						<div className="project-logo">
							{tools.map((tool, index) => (
								<img key={index} src={INFO.logos[tool]} alt={`logo-${index}`} />
							))}
						</div>
						<div className="project-link">
							Read More{" "}
							<FontAwesomeIcon
								style={{ fontSize: "10px" }}
								icon={faChevronRight}
							/>
						</div>
					</div>
				</Link>
			</div>
			<Modal isModalOpen={isModalOpen} onClose={closeModal}>
				<h2>{title}</h2>
				<Tabs tabs={details} />
				<div className="model-footer">
					<button className="model-close-button" onClick={closeModal}>Close</button>
				</div>
			</Modal>
		</React.Fragment>
	);
};

export default Project;
