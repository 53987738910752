const PROJECTS = [
    {
        title: "Bextract",
        description:
            "Bextract is an Enhanced Intelligent Document Processing solution that securely turns your unstructured & structured documents into actionable data. It leverages cloud technologies for high availability, scalability, and security..",
        tools: ["Angular", "AWS"],
        details: [
            {
                label: "Key Features",
                type: "features",
                content: [
                    { icon: "settings", text: "An AI archive and indexing solution, enabling search and analysis of “paperwork”." },
                    { icon: "chart", text: "Documents are scanned, and then automatically archived utilizing cloud resilient cloud services." },
                    { icon: "data", text: "Real-time note retrieval ensures increased operational efficiency." },
                    { icon: "roles", text: "AI, ML, and other techniques are used to verify, identify, and extract key information, which can then be analyzed, searched, and used in other workflows. " }
                ]
            },
            {
                label: "Video",
                type: "video",
                video: {
                    url: "https://www.linkedin.com/posts/bexprt_aws-activity-7263853859628888064-WL3V?utm_source=share&utm_medium=member_desktop",
                    thumbnail: "images/bexpert/bexpert.jfif",
                    title: "Bextract for Contracts Customer onboarding",
                    description: "How can Bextract automate your standard contract processes ?",
                    source: "linkedin.com"
                }
            },
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["Angular", "NG-Bootstrap", "Typescript", "Amplify", "Apexcharts", "Devextreme"],
                    backend: ["AWS Lambda", "AWS Bedrock", "Python", "AWS Cognito", "AWS Textract", "DynamoDB", "Elastic Search", "AWS S3", "AWS Cloud Front"],
                    devops: ["AWS Cloud Front", "AWS API Gateway", "Github Actions"],
                }
            },
        ],
    },

    {
        title: "Tamkeen",
        description:
            "Tamkeen is a software application designed to read and analyze invoices and payslips to compares the amounts invoiced with the payments made, enabling the Bahrain government to verify the accuracy of employee allowances and detect potential fraud.",
        tools: ["Angular", "AWS"],
        details: [
            {
                label: "Key Features",
                type: "features",
                content: [
                    { icon: "upload", text: "Implemented an upload component to handle large numbers of files and upload them to S3." },
                    { icon: "analytics", text: "Developed a document analysis process using AWS Textract to extract data from payslips and invoices." },
                    { icon: "chart", text: "Created charts to visualize the success and failure rates of documents, comparing payslip and invoice amounts." },
                    { icon: "table", text: "Built a table to display the failure and success percentages for each file." },
                    { icon: "roles", text: "Integrated AWS Cognito with AWS Amplify to provide secure user authentication and management." },
                ]
            },
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["Angular", "Angular Material", "Typescript", "Devextreme"],
                    backend: ["AWS Lambda", "Python", "AWS Cognito", "DynamoDB", "AWS S3",],
                    cloud: ["AWS Cloud Front", "AWS API Gateway"],
                    devops: ["Azure Devops"],
                }
            }
        ],
    },

    {
        title: "BowtieXP Enterprise",
        description:
            "BowtieXP is the most used risk assessment software that is based on the bowtie method. It enables you to easily create bowtie diagrams to assess risk. BowTieXP is unique in its ability to visualize complex risks in a way that is understandable, yet also allows for detailed risk-based improvement plans.",
        tools: ["React", "Azure", "TeamCity", ".NET Core", "C#", "Python", "WASM", "SQL"],
        details: [
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["React", "JavaScript", "Typescript", "Css", "ASP.NET Razor"],
                    backend: [".NET Core 8", "C#", "ASP.NET API Core", "ASP.NET MVC Core", "Python", "WASM", "SQL"],
                    cloud: ["Azure Database", "Azure App Services"],
                    devops: ["TeamCity", "JFrog", "Terraform"],
                }
            }
        ],
    },

    {
        title: "INOS",
        description: "INOS is a vendor agnostic, multi-network-technology solution delivering automated assessment, testing, benchmarking and optimization of networks. Generating actionable reports in just 15 minutes, combined with live-view for instant adjustments, INOS significantly reduces the time taken to complete the work and opex cost, to deliver optimum customer experience.",
        tools: ["Angular", "Node", "Docker", "Postgres"],
        details: [
            {
                label: "Key Features",
                type: "features",
                content: [
                    { icon: "settings", text: "Implemented a dynamic dashboard to enable end users to create their own customized dashboards." },
                    { icon: "chart", text: "Users can add tables, charts, maps, and gauges displaying both static and real-time data." },
                    { icon: "data", text: "Managed a large volume of data files received from mobile devices." },
                    { icon: "roles", text: "Implemented a robust role-based system for efficient user access and control." }
                ]
            },
            {
                label: "Summit",
                type: "video",
                video: {
                    url: "https://vimeo.com/803670096",
                    thumbnail: "https://i.vimeocdn.com/video/1622312314-01d4970ffed0e2adf3cd3a9ff7b6e3c84735f15125eefe18694d01f62f1dfbb4-d?f=webp",
                    title: "Digis Squared Using Intel at MWC 2023",
                    description: "We talk with Gwen Edwards on how Digis Squared is running on Intel and taking advantage of the Intel Network Builders testbed.",
                    source: "vimeo.com"
                }
            },
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["Angular", "NG-Bootstrap", "Typescript", "Devextreme", "Apexcharts"],
                    backend: ["Nodejs", "Postgres", "Docker"],
                    devops: ["Github Actions"],
                }
            },
        ]
    },

    {
        title: "Inview",
        description:
            "Inview is a web-based software to manage company's employee’s mobile SIM card usages. It enable your IT and Finance teams to manage the entire device estate and optimize costs.",
        tools: ["Angular", "Typescript", "Azure"],
        details: [
            {
                label: "Achieved Goals",
                type: "features",
                content: [
                    { icon: "refresh", text: "Led the complete rewrite of a complex, large-scale AngularJS application to the latest Angular version." },
                    { icon: "shield", text: "Addressed critical security issues identified during a penetration test to meet due diligence requirements." },
                    { icon: "code", text: "Implemented modern Angular best practices to enhance performance, scalability, and developer experience." },
                    { icon: "bug", text: "Ensured a seamless migration by guaranteeing all features worked flawlessly with no bugs introduced." },
                ]
            },
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["Angular", "NG-Bootstrap", "Typescript", "Devextreme"],
                    devops: ["Azure DevOps", "Azure Database", "Azure App Services"],
                }
            }
        ],
    },

    {
        title: "DND",
        description:
            "The DND Project is a software solution designed to manage the ordering of medicines between hospitals and medical suppliers. It facilitates order placement, generates invoices, and tracks the entire process from start to finish.",
        tools: ["Angular", "C#", "Azure", "SQL"],
        details: [
            {
                label: "Key Features",
                type: "features",
                content: [
                    { icon: "users", text: "Developed an application that connects customers with medical suppliers." },
                    { icon: "plus-circle", text: "Enabled users to create suppliers, manage customer profiles, and add medicines linked to specific suppliers." },
                    { icon: "shopping-cart", text: "Facilitated the placement of orders, including the calculation of purchase costs." },
                    { icon: "file-invoice", text: "Implemented functionality to generate invoices and detailed reports for transactions." }
                ]
            },
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["Angular", "Angular Material", "Typescript"],
                    backend: [".NET", "C#", "ASP.NET API", "SQL"],
                    cloud: ["Azure Database", "Azure App Services"],
                    devops: ["Azure Devops"],
                }
            }
        ],
    },

    {
        title: "SYL",
        description:
            "SYL is custom software developed for an Australian company to manage the booking of translators and interpreters, connecting them with clients. It handles availability, pricing, and integrates invoicing with the Xero accounting system.",
        tools: ["Angular", "Azure", "TeamCity", ".NET Core", "C#"],
        details: [
            {
                label: "Key Features",
                type: "features",
                content: [
                    { icon: "users", text: "Developed SYL software for managing interpreters, translators, their spoken languages, and the company clients." },
                    { icon: "calendar", text: "Enabled booking functionality for interpreters and translators, allowing clients to schedule sessions." },
                    { icon: "dollar-sign", text: "Implemented rate management for interpreters and translators, including per-hour rates." },
                    { icon: "file-invoice", text: "Generated invoices for both clients and service providers, with synchronization to the Xero accounting system." }
                ]
            },
            {
                label: "Technologies",
                type: "technologies",
                technologies: {
                    frontend: ["Angular", "Typescript", "Css"],
                    backend: [".NET 2", "C#", "ASP.NET API", "SQL", "Xero API"],
                    cloud: ["Azure Database", "Azure App Services"],
                    devops: ["TeamCity", "Octopus Deploy"],
                }
            }
        ],
    },
]

export default PROJECTS;
