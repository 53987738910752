import "./styles/video.css";

const Video = ({ video }) => {
    const { url, thumbnail, title, description, source} = video

    return (
        <div className="flex-container">
            <div className="left-section">
                <img src={thumbnail} alt="Conference" />
            </div>
            <div className="right-section">
                <h2 className="header">
                    <a className="video-header" href={url} rel="noopener nofollow"> {title} </a>
                </h2>
                <p className="description">
                    {description}
                </p>
                <span className="website">{source}</span>
            </div>
        </div>
    )
}

export default Video;