const INFO = {
	main: {
		title: "Wessam Yaacob",
		name: "Wessam Yaacob",
		email: "wessamsalah@live.com",
		logo: "../me.webp",
	},

	socials: {
		github: "https://github.com/wessamyaacob/",
		linkedin: "https://www.linkedin.com/in/wessam-yaacob/",
		stackoverflow: "https://stackoverflow.com/users/7122286/wessam-yaacob/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Senior Full-stack Web and Cloud developer.",
		description:
			"I'm a Senior Full Stack Developer with over 9 years of experience in building robust, scalable applications using technologies such as Angular, React, .NET, and AWS. My expertise spans across both frontend and backend development, with a strong focus on delivering high-quality, secure, and efficient solutions. I have a proven track record of leading teams, managing complex projects, and implementing clean architecture and best practices. Throughout my career, I’ve worked on a wide range of projects, from migration efforts (AngularJS to Angular 12) to developing cloud-based solutions with AWS. I’m passionate about solving problems and constantly enhancing my skill set, especially in areas like secure coding, AI, and cloud technologie.",
	},

	experiences: [
		{
			id: 1,
			company: "Wolters Kluwer Enablon",
			position: "Senior Product Software Engineer",
			date: "2020 - Present",
			location: "Den Haag, Netherlands",
			logo: "wk.jfif",
			responsibilities: [
				"Improved Angular application scalability by refactoring code for reusability and better dependency injection practices",
				"Introduced automated testing (unit and end-to-end) using Jasmine and Cypress, increasing application stability and reducing QA time by 50 % ",
				"Developed RESTful APIs using .NET Core for seamless integration with internal products and external customer applications",
				"Optimized development and deployment by integrating TeamCity, Terraform, and JFrog, improving continuous integration and feedback loop"
			]
		},
		{
			id: 2,
			company: "ICT Group",
			position: "Mid-Level Software Engineer",
			date: "2018 - 2020",
			location: "Eindhoven, Netherlands",
			logo: "ict.png",
			responsibilities: [
				"Led the upgrade of legacy Angular applications from AngularJs to Angular 9, following best practices to ensure compatibility and stability",
				"Assisted in debugging and optimizing frontend code to meet performance benchmarks.",
				"Used Azure DevOps to construct a pipeline to automate continuous build, integration, testing, and deployments"
			]
		},
		{
			id: 3,
			company: "52ndSolution",
			position: "Full Stack Software Engineer",
			date: "2017 - 2018",
			location: "Remote, Australia",
			logo: "52nd.jfif",
			responsibilities: [
				"Designed and developed a web app for managing the booking and financing process of requesting translators and interpreters per hour/day using Angular 2 and C# REST APIs",
				"Developed wizard-style portal for candidates to fill in their data ex (Personal Info, Education, Employment History, Criminal History and allow User to sign) using Angular 2 and C# REST APIs",
				"Implemented the integration layer between the accounting module and the online accounting system (Xero)",
				"Enhanced old angular applications loading speed by using angular AOT complier and webpack to split the large JS files"
			]
		},
		{
			id: 4,
			company: "GET Group",
			position: "Software Developer",
			location: "Cairo, Egypt",
			date: "2015 - 2017",
			logo: "get_group.jfif",
			responsibilities: [
				"Developed and Maintained features of the desktop application connected to vehicles License plates camera detector to display accurate car data captured by the cameras in Realtime using C# and WPF",
				"Implemented windows service using C# to sync data from the desktop application to the backend web application",
				"Developed and maintained web portal to show the desktop app data statistics using Asp.Net MVC with Razor, kendo UI Ajax, jQuery, C#",
				"Involved in prototyping mobile app for outdoor employee’s work attendance time & location using Cordova"
			]
		}
	],

	logos: {
		"Angular": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg",
		"Typescript": "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/typescript/typescript.png",
		"AWS": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
		".NET Core": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dotnetcore/dotnetcore-original.svg",
		"Azure": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg",
		"React": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
		"C#": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg",
		"Python": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
		"TeamCity": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/TeamCity_Icon.svg/512px-TeamCity_Icon.svg.png?20200803084943",
		"GithubActions": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/githubactions/githubactions-original.svg",
		"Node": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
		"Docker": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg",
		"Postgres": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
		"WASM": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wasm/wasm-original.svg",
		"SQL": "https://symbols.getvecta.com/stencil_28/61_sql-database-generic.90b41636a8.svg"
	},

	faIcons: {
		"gauge": "fa-solid fa-gauge",
		"map": "fa-solid fa-map",
		"settings": "fa-solid fa-gear",
		"users": "fa-solid fa-user",
		"chart": "fa-solid fa-chart-simple",
	},

	icons: {
		"gauge": "📡",
		"map": "🗺️",
		"settings": "⚙️",
		"users": "👥",
		"chart": "📉",
		"table": "📋",
		"real_time": "⏱️",
		"roles": "🔐",
		"data": "🗃️",
		"shield": "🛡️",
		"code": "💻",
		"refresh": "🔄",
		"bug": "🐞",
		"upload": "⬆️",
		"analytics": "📊",
		"plus-circle": "➕",
		"shopping-cart": "🛒",
		"file-invoice": "🧾",
		"calendar": "📅",
		"dollar-sign": "💲"
	},
};

export default INFO;
