import React, { useState } from "react";
import "./styles/tabs.css";
import INFO from "../../data/user";
import Video from "./video";
import Technologies from "../projects/technologies";

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="tabs-container">
            <div className="tabs-header">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={`tab-button ${activeTab === index ? "active" : ""}`}
                        onClick={() => setActiveTab(index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            <div className="tabs-content">
                {(() => {
                    const activeTabContent = tabs[activeTab];

                    if (activeTabContent?.type === "features") {
                        return (
                            <ul className="features">
                                {activeTabContent.content?.map((item) => (
                                    <li key={item.id || item.icon}>
                                        {INFO.icons[item.icon]} {item.text}
                                    </li>
                                ))}
                            </ul>
                        );
                    }

                    if (activeTabContent?.type === "video") {
                        return <Video  video={activeTabContent.video}/>;
                    }

                    if (activeTabContent?.type === "technologies") {
                        return <Technologies tech={activeTabContent.technologies} />;
                    }

                    else {
                        return activeTabContent?.content;
                    }
                })()}
            </div>

        </div>
    );
};

export default Tabs;
