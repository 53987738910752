import React from 'react';
import './styles/tag.css'; 

const Tag = ({ label }) => {
  return (
    <div className="tag">
      <span className="tag-label">{label}</span>
    </div>
  );
};

export default Tag;
