import React from "react";


import { Link } from "react-router-dom";
import INFO from "../../data/user";
import "./styles/experience-list.css";

const ExperiencesList = () => {
	return (
		<React.Fragment>
			{INFO.experiences.map((project) => (
				<div className="experience" key={project.id}>
					<div className="experience-left-side">
						<div className="experience-date">{project.date}</div>
						<div className="experience-location">{project.location}</div>
					</div>

					<Link >
						<div className="experience-right-side">
							<div className="experience-header-container">
								<img
									src={project.logo}
									alt="facebook"
									className="experience-logo"
								/>
								<div className="experience-company">{project.company}</div>
							</div>
							<div className="experience-position">{project.position}</div>
							<ul>
								{project.responsibilities.map((item, index) => (
									<li key={`${project.company}-${index}`}>
										{item}
									</li>
								))}
							</ul>
						</div>
					</Link>
				</div>
			))}

		</React.Fragment>
	);
};

export default ExperiencesList;
