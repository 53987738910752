import React, { useEffect } from "react";

const Summary = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <section>
                <h2>Area of Expertise</h2>
                <p>⭐ 10 years experience in full stack web-development.</p>
                <p>⭐ Frontend, Backend, and Cloud Enthusiast.</p>
                <p>⭐ Expert in building REST APIs using .NET Core, C#, AWS Lambda, and Python.</p>
                <p>⭐ Expert in upgrading Angular applications from AngularJS or Angular 2+ to the latest versions.</p>
                <p>⭐ Expert in Architecting Angular applications following Clean Architecture.</p>
                <p>I have worked with customers from Europe (UK, Netherlands), UAE, Saudi Arabia, and Australia. I have no issues with time zones or communication.</p>
            </section>

            <section>
                <h2>Skills</h2>
                <ul>
                    <li>Software architecture, patterns, and best practices</li>
                    <li>Frontend: Angular, React, TypeScript, JavaScript, Devextreme, Angular Material, Akita, Jasmine, Karma, Ngrx, NG-Bootstrap, AWS Amplify, Apexcharts</li>
                    <li>Backend: .NET Core, API development/integration, NodeJs, Python</li>
                    <li>Database: PostgreSQL, Microsoft SQL Server</li>
                    <li>HTML5, CSS, Sass, Scss, Less</li>
                    <li>Cloud: AWS, Azure</li>
                    <li>Version Control: Git</li>
                </ul>
            </section>

            <section>
                <h2>What I can offer you</h2>
                <ul>
                    <li>Frontend Angular Applications</li>
                    <li>REST API Services</li>
                    <li>Booking Systems</li>
                    <li>Risk Management Systems</li>
                    <li>Interactive/Dynamic Dashboards</li>
                    <li>AngularJS Migration to Newer Versions</li>
                    <li>Web Scraping</li>
                    <li>AWS Cognito and Keycloak Integration</li>
                    <li>Troubleshooting production issues</li>
                </ul>
            </section>
        </React.Fragment>
    );
};

export default Summary;
