import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import NavBar from "../components/common/navBar";

import INFO from "../data/user";

import ExperiencesList from "../components/experiences/experiences-list";

import "./styles/experiences.css";

const Experiences = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<React.Fragment>
			<Helmet>
				<title>{`Experiences | ${INFO.main.title}`}</title>
			</Helmet>

			<div className="page-content">
				<NavBar active="experiences" />
				<div className="content-wrapper">
					<div className="experiences-logo-container">
						<div className="experiences-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="experiences-container">
						<div className="title">
							Companies I’ve helped them making great softwares.
						</div>

						<div className="subtitle">
							I've worked on a variety of projects over the 9 years with
							customers from Europe (UK, Netherlands), 
							UAE, Saudi Arabia, and Australia. 
							I do not have any problems with time zone and communication.
						</div>

						<div className="experiences-list">
							<ExperiencesList />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Experiences;
