import './styles/modal.css';

const Modal = ({ isModalOpen, children, onClose }) => {
    if (!isModalOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                {children}
                <button className="close-icon-button" onClick={onClose} >×</button>
            </div>
        </div>
    );
};

export default Modal;
