import React from 'react';
import Tag from '../common/tag';

const Technologies = ({ tech }) => {
    const { frontend, backend, cloud, devops } = tech;

    return (
        <div className="app-container">

            <div className="section">
                {frontend && (
                    <div className="tags-section">
                        <label className="section-label">Frontend:</label>
                        <div className="tags-list">
                            {frontend.map((tag, index) => (
                                <Tag key={index} label={tag} />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className="section">
                {backend && (
                    <div className="tags-section">
                        <label className="section-label">Backend:</label>
                        <div className="tags-list">
                            {backend.map((tag, index) => (
                                <Tag key={index} label={tag} />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className="section">
                {cloud && (
                    <div className="tags-section">
                        <label className="section-label">Cloud:</label>
                        <div className="tags-list">
                            {cloud.map((tag, index) => (
                                <Tag key={index} label={tag} />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className="section">
                {devops && (
                    <div className="tags-section">
                        <label className="section-label">DevOps:</label>
                        <div className="tags-list">
                            {devops.map((tag, index) => (
                                <Tag key={index} label={tag} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Technologies;
